import React from "react";
import "../Styling/ScrollingSection.css";

const ScrollingSection = () => {
  return (
    <div className="section-full">
      <div className="scroll-wrap">
        <div
          className="scroll-holder"
          style={{
            transform:
              "translate3d(-42.941%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
            willChange: "transform",
          }}
        >
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item-wrap">
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
            </div>
            <div className="scroll-item-wrap">
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
            </div>
            <div className="scroll-item-wrap">
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
            </div>
            <div className="scroll-item-wrap">
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
            </div>
            <div className="scroll-item-wrap">
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
            </div>
            <div className="scroll-item-wrap">
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
              <div className="scroll-item">
                <h1 className="scroll-heading">GOT JUICE?</h1>
                <div className="scroll-divider"></div>
              </div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
          <div className="scroll-item-wrap">
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
            <div className="scroll-item">
              <h1 className="scroll-heading">GOT JUICE?</h1>
              <div className="scroll-divider"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollingSection;
